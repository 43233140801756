<!--选择发货单-->
<template>
    <div class="chooseInvoice tableList" v-loading="loading">
        <div class="tableListBox">
            <div class="tableHeader">
                <div class="el_left">
                    <el-button @click="returnSelectData">
                        确定
                    </el-button>
                </div>
                <div class="el_right ">
                    <div class="el_left generalQuery">
                        <el-input placeholder="请输入搜索内容..." v-model="generalQueryText" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="generalQuery"></el-button>
                        </el-input>
                    </div>
                    <div class="el_left setUp">
                        <el-button v-popover:popover>
                            <i class="iconfont iconshezhi"></i>
                        </el-button>
                        <el-popover
                            ref="popover"
                            placement="bottom-start"
                            title="自定义列表项"
                            width="540"
                            trigger="click"
                            v-model="isPopover"
                        >
                            <div class="tablePopover">
                                <div class="popoverContent">
                                    <el-checkbox-group v-model="selectListItem">
                                        <template v-for="(item, index) in listDisplayItems">
                                            <el-checkbox :key="index" :label="item.field">
                                                {{ item.title }}
                                            </el-checkbox>
                                        </template>
                                    </el-checkbox-group>
                                </div>
                                <div class="el_right">
                                    <el-button size="mini" @click="isPopover = false">
                                        取消
                                    </el-button>
                                    <el-button size="mini" type="primary" @click="confirmListItem">
                                        确定
                                    </el-button>
                                </div>
                            </div>
                        </el-popover>
                    </div>
                </div>
            </div>
            <div class="check-status-box">
                <div class="check-content">
                    <div class="title">
                        本对账期
                    </div>
                    <div class="status-list">
                        <el-radio-group v-model="curCheckStatus" @change="(val)=>checkStatusChange(currentInfo.period_number,val)">
                            <el-radio label="">
                                全部:{{ currentInfo.total_quantity }}个
                            </el-radio>
                            <el-radio :label="0">
                                未办结:{{ currentInfo.unfinished }}个
                            </el-radio>
                            <el-radio :label="1">
                                已办结未核对:{{ currentInfo.completed_but_not_checked }}个
                            </el-radio>
                            <el-radio :label="2">
                                已核对未核算:{{ currentInfo.checked_but_not_accounted }}个
                            </el-radio>
                            <el-radio :label="3">
                                已核算未对账:{{ currentInfo.accounted_but_not_reconciled }}个
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="check-content">
                    <div class="title">
                        前对账期
                    </div>
                    <div class="status-list">
                        <el-radio-group v-model="prevCheckStatus" @change="(val)=>checkStatusChange(previousInfo.period_number,val)">
                            <el-radio label="">
                                全部:{{ previousInfo.total_quantity }}个
                            </el-radio>
                            <el-radio :label="0">
                                未办结:{{ previousInfo.unfinished }}个
                            </el-radio>
                            <el-radio :label="1">
                                已办结未核对:{{ previousInfo.completed_but_not_checked }}个
                            </el-radio>
                            <el-radio :label="2">
                                已核对未核算:{{ previousInfo.checked_but_not_accounted }}个
                            </el-radio>
                            <el-radio :label="3">
                                已核算未对账:{{ previousInfo.accounted_but_not_reconciled }}个
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="tableContent">
                <div class="table-bg infinite-list-wrapper">
                    <el-table
                        border
                        stripe
                        :data="tableList"
                        ref="multipleTable"
                        @row-click="selateTable"
                        :highlight-current-row="true"
                        @selection-change="handleSelectionChange"
                        class="table-list sum-Tble invoiceTable"
                        :row-style="getRowStyle"
                    >
                        <el-table-column
                            key="select"
                            type="selection"
                            width="55"
                            :selectable="selectable"
                        >
                        </el-table-column>
                        <template v-for="(item,index) in tableHeader">
                            <el-table-column
                                :key="index"
                                :prop="item.field"
                                :label="item.title"
                                :width="item.width"
                            >
                                <template slot-scope="scope">
                                    <!--表格内link列-->
                                    <div
                                        v-if="item.link"
                                        :style="item.style"
                                    >
                                        <el-link
                                            :type="item.link.linkType"
                                            @click.stop="handleLinkClick(item.link, scope.row)"
                                        >
                                            {{ scope.row[item.field] }}
                                        </el-link>
                                    </div>
                                    <div
                                        v-else-if="item.state"
                                        :style="item.style"
                                    >
                                        {{ item.state[scope.row[item.field]] }}
                                    </div>
                                    <div
                                        v-else
                                        :style="item.style"
                                    >
                                        {{ scope.row[item.field] }}
                                    </div>
                                </template>
                            </el-table-column>
                        </template>
                    </el-table>
                </div>
                <div class="table-page">
                    <div class="el_right">
                        <el-pagination
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page"
                            :page-sizes="[20,40,60,80,100]"
                            :page-size="limit"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';

export default {
    name: 'currency-table',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String, Number],
        },
    },
    data() {
        return {
            loading: false,
            // 查询条件
            generalQueryText: '',
            // 自定义列表项数据
            listDisplayItems: [],
            // 已选中显示项
            selectListItem: [],
            // 列表项选择弹出层状态
            isPopover: false,
            // 列表头部数据
            tableHeader: [{
                title: '发货单号',
                field: 'delivery_receipt_number',
                width: '210',
            }, {
                title: '核对状态',
                field: 'check_status',
                width: '120',
                state: {
                    1: '未核对',
                    2: '已核对',
                    3: '调整后核对',
                    4: '已核算',
                    5: '调整后核算',
                    6: '已对账',
                    7: '已结算',
                },
            }, {
                title: '车牌号',
                field: 'plate_number',
            }, {
                title: '车号',
                field: 'car_code',
            }, {
                title: '任务车次',
                field: 'task_current_trains',
            }, {
                title: '发货时间',
                field: 'deliver_time',
                width: '180',
            }, {
                title: '发货方量',
                field: 'deliver_volume',
            }, {
                title: '退货方量',
                field: 'back_volume',
            }, {
                title: '签收时间',
                field: 'sign_time',
                width: '180',
            }, {
                title: '调整后签收方量',
                field: 'adjusted_sign_volume',
            }, {
                title: '砼单价',
                field: 'concrete_price',
            }, {
                title: '调整后砼单价',
                field: 'adjusted_concrete_price',
            }],
            // 列表数据
            tableList: [],
            // 数据总条数
            total: 0,
            // 当前页码
            page: 1,
            // 每页显示条目个数
            limit: 20,
            // 是否显示复选框
            showCheckBox: false,
            // 列表选择数据集合
            tableSelectData: [],

            // 本期
            currentInfo: {
                total_quantity: 0,
                unfinished: 0,
                completed_but_not_checked: 0,
                checked_but_not_accounted: 0,
                accounted_but_not_reconciled: 0,
                period_number: 0, // 期数目
            },
            // 前期
            previousInfo: {
                total_quantity: 0,
                unfinished: 0,
                completed_but_not_checked: 0,
                checked_but_not_accounted: 0,
                accounted_but_not_reconciled: 0,
                period_number: 0,
            },
            statement_number: '', // 对账单编号
            statement_start_time: '', // 开始时间
            statement_end_time: '', // 结束时间
            time_format: '', // 时间格式
            period_number: '', // 期数目
            // 核算状态
            check_status: null,
            curCheckStatus: null,
            prevCheckStatus: null,
        };
    },
    watch: {},
    computed: {},
    created() {
        this.statement_number = this.$queryString(this.extr.code.QueryString, 'statement_number');
        this.statement_start_time = this.$queryString(this.extr.code.QueryString, 'statement_start_time');
        this.statement_end_time = this.$queryString(this.extr.code.QueryString, 'statement_end_time');
        this.time_format = this.$queryString(this.extr.code.QueryString, 'time_format');
        this.listDisplayItems = this.tableHeader;
        this.selectListItem = this.tableHeader.map(item => item.field);
        this.getTableData();
    },
    mounted() {
        // 添加事件总线eventBus/table刷新方法
        eventBus.$on('updateTable', () => {
            this.getTableData();
        });
    },
    methods: {
        // 设置行样式。禁用时候灰色
        getRowStyle({ row }) {
            if (this.selectable(row)) {
                return { opacity: 1 };
            }
            return { opacity: 0.6 };
        },
        // 是否能选中
        selectable(row) {
            // eslint-disable-next-line eqeqeq
            if ([4, 5].includes(row.check_status)) {
                return true;
            }
            return false;
        },
        // 选中状态变化
        checkStatusChange(period_number, val) {
            this.check_status = val;
            this.period_number = period_number;
            if (this.currentInfo.period_number === period_number) {
                this.prevCheckStatus = null;
            } else {
                this.curCheckStatus = null;
            }
            this.getTableData();
        },
        // 点击行选中
        selateTable(row) {
            if (this.selectable(row)) {
                this.tableSelectData = [];
                this.$refs.multipleTable.toggleRowSelection(row);
                this.tableSelectData = this.$refs.multipleTable.selection;
            } else {
                this.$message.warning('当前发货单未核算，不能选择');
            }
        },
        // 多选选中数据集合
        handleSelectionChange() {
            this.tableSelectData = this.$refs.multipleTable.selection;
        },
        // 确认选择列表显示项
        confirmListItem() {
            const newColumns = [];
            this.listDisplayItems.forEach(k => {
                this.selectListItem.forEach(item => {
                    if (item === k.field) {
                        newColumns.push(k);
                    }
                });
            });
            this.tableHeader = newColumns;
            this.isPopover = false;
        },
        returnSelectData() {
            const data = this.tableSelectData || [];
            if (data.length === 0) {
                this.$message('请选择发货单！');
                return;
            }
            const frameId = this.$queryString(this.extr.code.QueryString, 'frameId');
            const form = document.getElementById(frameId).contentWindow;
            form.returnDeliveryData(data);
        },
        // 列表上link点击方法
        handleLinkClick(link, row) {
            this.$emit(link.method, row);
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getTableData();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getTableData();
        },
        // 查询
        generalQuery() {
            this.getTableData();
        },
        // 获取列表数据
        getTableData() {
            this.loading = true;
            this.getTableList();
        },
        // post请求
        getTableList() {
            this.$axios
                .post('/interfaceApi/sale/accounts/get_whole_delivery_receipt', {
                    keyword: this.generalQueryText,
                    pageindex: this.page,
                    pagesize: this.limit,
                    period_number: this.period_number,
                    check_status: this.check_status,
                    statement_number: this.statement_number,
                    statement_start_time: this.statement_start_time,
                    statement_end_time: this.statement_end_time,
                    time_format: Number(this.time_format),
                })
                .then(res => {

                    if (res.currentInfo) {
                        this.currentInfo = res.currentInfo;
                    }
                    if (res.previousInfo) {
                        this.previousInfo = res.previousInfo;
                    }
                    this.tableList = res.list.rows;
                    // this.$set(this, 'tableList', res.list.rows);
                    // 数据总条数
                    this.total = res.list.total;
                    // 强制刷新
                    this.$forceUpdate();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    beforeDestroy() {
        // 清除事件总线eventBus
        eventBus.$off('updateTable');
    },
};
</script>

<style lang="stylus">
.chooseInvoice
    height 100%
    padding 0.14rem
    background #fff
    .el-table
        height 100%
    .tableHeader
        padding-bottom 0;
    .check-status-box
        width 100%;
        display flex
        .check-content
            width 50%;
            display flex
            flex-direction column
            &:nth-child(2)
                margin-left -1px;
            .title
                padding-left 0.05rem;
                width 0.8rem;
                height 0;
                border-width  0 0.2rem 0.2rem 0rem;
                border-style none solid solid
                border-color transparent transparent #F5F2ED
                font-size 0.12rem;
                color #5D4920;
            .status-list
                box-sizing border-box
                width 100%;
                height 0.44rem;
                display flex
                align-items center
                border: 1px solid #D7D7D7;
                padding-left 0.1rem;
                .el-radio
                    font-size 0.14rem;
                    color #595959
</style>
